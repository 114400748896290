import React, { useEffect, useState } from 'react'
import { Field, Form, reduxForm, getFormValues } from 'redux-form'
import forgotPasswordFields from '../../config/forgotPassword-fields'
import { requestResetPassword } from '../../utils/helpers'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Paper, Typography, Button, Box, FormHelperText, CircularProgress } from '@material-ui/core'
import { emailValidationSchema } from '../../utils/validationSchema'
import validationService from '../../services/validation-service'
import styles from './ForgotPasswordStyles'
import { useTranslation } from 'react-i18next'
import withInit from '../../components/containers/withInit'
const useStyles = makeStyles(styles)

const ForgotPassword = props => {
  const classes = useStyles()
  const [emailSent, setEmailSent] = useState(false)
  const [resetError, setResetError] = useState(null)

  const {
    error,
    metaData,
    handleSubmit,
    asyncValidating,
    valid,
    match: {
      params: { partnerId = '' },
    },
    submitting,
    isSubmitting,
    setIsSubmitting,
  } = props
  const { localisation = {} } = metaData
  const submitDisabled = !valid || asyncValidating || submitting

  const { t } = useTranslation('pageText')

  useEffect(() => {
    document.title = 'Forgot Your Password?'
  }, [t])

  const onResetPassword = async values => {
    setIsSubmitting(true)
    await requestResetPassword(values).then(res => {
      setEmailSent(true)
      if (res.result === 1) {
        setEmailSent(true)
      } else {
        setResetError(true)
      }
      setIsSubmitting(false)
    })
  }

  const fields = forgotPasswordFields(localisation.pageText)

  const errorMessage = (
    <Box className={classes.header}>
      <Typography component="h3" variant="h5" color="primary" className={classes.title}>
        Something went wrong
      </Typography>
      <Typography component="p" className={classes.paragraph}>
        Sorry, we can not change your password at this moment
      </Typography>
      <Button variant="contained" href={`/forgottenPassword/${partnerId}`} color="primary" className={classes.button}>
        Retry Later
      </Button>
    </Box>
  )

  const passwordRequestWasSent = (
    <Box className={classes.header}>
      <Typography component="h3" variant="h5" color="primary" className={classes.title}>
        Password reset requested
      </Typography>
      <Typography component="p" className={classes.paragraph}>
        We have sent you an email. When you receive it, please click on the link provided to reset your password.
      </Typography>
      <Button variant="contained" href={`/login/${partnerId}`} color="primary" className={classes.button}>
        Login
      </Button>
    </Box>
  )

  const resetPasswordForm = (
    <>
      <Box className={classes.header}>
        <Typography component="h3" variant="h5" color="primary" className={classes.title}>
          Forgot Your Password?
        </Typography>
      </Box>
      <Form className={classes.form} onSubmit={handleSubmit(onResetPassword)}>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <Field {...fields.email} disabled={isSubmitting} />
          </Grid>
          {error && (
            <Grid item container md={12} sm={12} xs={12}>
              <Paper elevation={0} className={classes.errorMessage}>
                <FormHelperText className={classes.errorHelper}>{error}</FormHelperText>
              </Paper>
            </Grid>
          )}

          <Grid item container md={12} sm={12} xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              color="primary"
              className={classes.submit}
              disabled={submitDisabled || isSubmitting}
            >
              {isSubmitting && <CircularProgress size={16} className={classes.spinner} />}
              Reset Password
            </Button>
          </Grid>
        </Grid>
      </Form>
    </>
  )

  return resetError ? errorMessage : emailSent ? passwordRequestWasSent : resetPasswordForm
}

const ForgotPasswordRedux = reduxForm({
  form: 'resetPassword',
  validate: validationService.syncValidation(emailValidationSchema),
  enableReinitialize: false,
})(ForgotPassword)

const mapStateToProps = state => {
  const {
    form: { resetPassword },
  } = state

  const initialValues = {
    email: localStorage.getItem('latestEmailInput') || '',
  }

  return {
    formValues: getFormValues('resetPassword')(state),
    resetPassword,
    initialValues,
  }
}

const connectedResetPassword = connect(mapStateToProps)(ForgotPasswordRedux)

export default withInit(connectedResetPassword)
