import React, { useEffect } from 'react'

import { Box, Button, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { ReactComponent as Logo } from '../../assets/icompleat.svg'
import styles from './NotFoundStyles'

const useStyles = makeStyles(styles)

const NotFound = props => {
  const classes = useStyles()
  useEffect(() => {
    document.title = '404 - Page Not Found'
  }, [])

  return (
    <Paper className={classes.paper} elevation={0}>
      <Box className={classes.header}>
        <Logo />
        <Typography component="h2" variant="h4" className={classes.headline}>
          Oops! The page you were looking for doesn’t exist.
        </Typography>
        <Typography component="p" className={classes.paragraph}>
          You may have mistyped the address. But you still have two options:
        </Typography>
        <Box className={classes.buttonContainer}>
          <Button variant="contained" href="/login" color="primary" className={classes.button}>
            Login
          </Button>
          or
          <Button variant="contained" href="/register" color="primary" className={classes.button}>
            Register
          </Button>
        </Box>
      </Box>
    </Paper>
  )
}

export default NotFound
