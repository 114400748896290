import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import IEMessage from './components/IEMessage/IEMessage'

// detect old IE and Trident
const ua = navigator.userAgent;
const isIE = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;


ReactDOM.render(isIE ? <IEMessage /> : <App />, document.getElementById('root'))
