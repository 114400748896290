import React, { useState } from 'react'

import { Box, Grid, Hidden, LinearProgress, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import styles from '../../Pages/SignUpForm/SignUpFormStyles'
import { ReactComponent as CompleatLogo } from '../../assets/compleat.svg'
import { ReactComponent as ICompleatLogo } from '../../assets/icompleat.svg'
import Footer from '../Footer'

const useStyles = makeStyles(styles)

const TwoColumnLayout = ({
  customLogo,
  isPartner,
  isCompleat,
  tenantName,
  socialMedia,
  loading,
  children,
  leftColumnWidth,
  rightColumnWidth,
}) => {
  const classes = useStyles(styles)
  const [isSubmitting, setIsSubmitting] = useState(false)

  let logo = customLogo ? <img src={customLogo} alt="Logo" /> : <ICompleatLogo />

  if(isCompleat) logo = <CompleatLogo />  
  
  return (
    <>
      <Grid container component="main" className={classes.root}>
        <Hidden smDown>
          <Grid item xs={false} sm={false} md={leftColumnWidth} className={classes.image} />
        </Hidden>
        <Grid
          item
          xs={12}
          sm={12}
          md={rightColumnWidth}
          component={Paper}
          elevation={2}
          square
          className={classes.formContainer}
        >
          {isSubmitting && <LinearProgress className={classes.linearProgress} />}
          <Paper className={classes.paper} elevation={0}>
            <Box className={classes.header}>{logo}</Box>
            {React.cloneElement(children, { isSubmitting, setIsSubmitting, tenantName })}
          </Paper>
          {!isPartner && <Footer socialMedia={socialMedia} />}
        </Grid>
      </Grid>
    </>
  )
}

export default TwoColumnLayout
