const styles = theme => ({
  footer: {
    padding: theme.spacing(6, 2),
    // margin: theme.spacing(-2, 0),
    transform: 'TranslateY(-100%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    width: '160px',
    justifyContent: 'space-between',
  },
  icon: {
    color: '#e5e5e5',
    transition: 'all .25s ease-in',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  copy: {
    display: 'block',
    color: theme.palette.text.hint,
  },
  copyLink: {
    color: theme.palette.text.hint,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
})

export default styles
