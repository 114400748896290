import React from 'react'

import { FormHelperText, Grid, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import styles from './ErrorMessageStyles'

const useStyles = makeStyles(styles)

const ErrorMessage = ({ error = undefined }) => {
  const classes = useStyles()
  if (!error) return null
  
  return (
    <Grid item container md={12} sm={12} xs={12}>
      <Paper elevation={0} className={classes.errorMessage}>
        <FormHelperText className={classes.errorHelper}>{error}</FormHelperText>
      </Paper>
    </Grid>
  )
}

export default ErrorMessage
