import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Field, Form, getFormValues, reduxForm } from 'redux-form'

import { Button, FormHelperText, Grid, Link, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import AzureAuthenticationButton from '../../components/AuthenticationButton/AzureAuthenticationButton'
import ErrorMessage from '../../components/ErrorMessage'
import withInit from '../../components/containers/withInit'
import loginFields from '../../config/login-fields'
import validationService from '../../services/validation-service'
import { login, useLocalStorage } from '../../utils/helpers'
import { signInValidationSchema } from '../../utils/validationSchema'
import styles from './SignInFormStyles'

const useStyles = makeStyles(styles)

const SignInForm = props => {
  const classes = useStyles()
  const { t } = useTranslation('pageText')
  const [showPassword, setShowPassword] = useState(false)
  const [, setLatestEmail] = useLocalStorage('latestEmailInput', '')
  const {
    formValues,
    error,
    handleSubmit,
    asyncValidating,
    pristine,
    valid,
    match: {
      params: { partnerId = '' },
    },
    localisation,
    location,
    submitting,
    setIsSubmitting,
    isSubmitting,
  } = props

  const submitDisabled = !!(pristine || !valid || asyncValidating)
  const qs = queryString.parse(location.search)

  const rtMapper = {
    '2': {
      message: 'Your password has been reset!',
      paperClassName: 'passwordReset',
      textClassName: 'passwordResetMessage',
    },
    '3': {
      message: 'Your session has timed out. Please log in again.',
      paperClassName: 'errorMessage',
      textClassName: 'errorHelper',
    },
  }

  const hasQueryRT = qs.rt || undefined

  const RTMessage = ({ message, paperClassName, textClassName }) => (
    <Grid container>
      <Grid item container md={12} sm={12} xs={12}>
        <Paper elevation={0} className={classes[paperClassName]}>
          <FormHelperText className={classes[textClassName]}>{message}</FormHelperText>
        </Paper>
      </Grid>
    </Grid>
  )

  useEffect(() => {
    if (formValues && formValues.hasOwnProperty('email')) setLatestEmail(formValues.email)
  }, [formValues, setLatestEmail])

  useEffect(() => {
    document.title = t('pageTitle')
  }, [t])

  useEffect(() => {
    if (submitting) {
      setIsSubmitting(true)
    }
  }, [submitting, setIsSubmitting])

  useEffect(() => {
    if (error) setIsSubmitting(false)
  }, [error, setIsSubmitting])

  const fields = loginFields(localisation.pageText)
  return (
    <>
      <Typography component="h3" variant="h5" color="primary" className={classes.title}>
        Log In
      </Typography>

      <AzureAuthenticationButton
        className={classes.msButton}
        buttonText='Sign in with Microsoft'
      />

      <div className={classes.separator} />

      <Form className={classes.form} onSubmit={handleSubmit}>
        {hasQueryRT && <RTMessage {...rtMapper[qs.rt]} />}
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <Field {...fields.email} disabled={isSubmitting} />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Field
              {...fields.password}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              disabled={isSubmitting}
            />
          </Grid>
          <ErrorMessage error={error} />

          <Grid item container md={12} sm={12} xs={12}>
            <Button
              id="LogInButton"
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              color="primary"
              className={classes.submit}
              disabled={submitDisabled || isSubmitting}
            >
              {t('loginButton')}
            </Button>
          </Grid>

          <Grid container item md={12} xs={12} direction="row" justifyContent="space-between" alignItems="center">
            <Field {...fields.rememberMe}>{t('rememberMe')}</Field>
            <Link href={`/forgottenPassword/${partnerId}`}>{t('forgottenPasswordPrompt')}</Link>
          </Grid>
        </Grid>
      </Form>
    </>
  )
}

const ReduxSignInForm = reduxForm({
  form: 'signInForm',
  validate: validationService.syncValidation(signInValidationSchema),
  onSubmit: login,
  enableReinitialize: true,
})(SignInForm)

const mapStateToProps = state => {
  const {
    form: { signInForm },
  } = state

  const initialValues = {
    email: localStorage.getItem('username') || '',
  }

  return {
    formValues: getFormValues('signInForm')(state),
    signInForm,
    initialValues,
  }
}

const connectedSignInForm = connect(mapStateToProps)(ReduxSignInForm)

export default withInit(connectedSignInForm)
