import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Field, Form, getFormValues, reduxForm } from 'redux-form'

import { Button, CircularProgress, Grid, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import ErrorMessage from '../../components/ErrorMessage'
import withInit from '../../components/containers/withInit'
import fields from '../../config/registration-fields'
import validationService from '../../services/validation-service'
import { registerConfirm } from '../../utils/helpers'
import { signUpConfirmValidationSchema } from '../../utils/validationSchema'
import styles from './SignUpConfirmStyles'

const useStyles = makeStyles(styles)

const SignUpConfirm = props => {
  const classes = useStyles()
  const {
    error,
    metaData,
    hasInviteCode,
    handleSubmit,
    asyncValidating,
    pristine,
    valid,
    submitting,
    isSubmitting,
    setIsSubmitting,
  } = props
  const [showPassword, setShowPassword] = useState(false)
  const submitDisabled = !!(pristine || !valid || asyncValidating)

  const shouldShowTermsAndConditions = metaData.registrationType !== 3


  useEffect(() => {
    document.title = 'Register confirm'
  }, [])

  useEffect(() => {
    if (submitting) {
      setIsSubmitting(true)
    }
  }, [submitting, setIsSubmitting])

  useEffect(() => {
    if (error) setIsSubmitting(false)
  }, [error, setIsSubmitting])

  return (
    <>
      <Form className={classes.form} onSubmit={handleSubmit}>
        <Grid container spacing={2}>          
          <Grid item md={12} xs={12} className={classes.passwords}>
            <Field
              {...fields.password}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item md={12} xs={12} className={classes.passwords}>
            <Field
              {...fields.confirmPassword}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              disabled={isSubmitting}
            />
          </Grid>
          {hasInviteCode && (
            <Grid item md={12} xs={12}>
              <Field {...fields.inviteCode} disabled={isSubmitting} />
            </Grid>
          )}

          {shouldShowTermsAndConditions && (
            <Grid item md={12} xs={12}>
              <Field {...fields.acceptTerms}>
                By clicking you agree to{' '}
                <Link href="https://www.compleatsoftware.com/icompleat-terms-and-conditions" target="_blank" rel="noopener noreferrer">
                  Compleat terms of use
                </Link>{' '}
                and{' '}
                <Link href="https://www.compleatsoftware.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                  privacy policy
                </Link>
              </Field>
            </Grid>
          )}

          <Grid item md={12} xs={12} className={classes.recaptcha}>
            <Field {...fields.recaptcha} siteKey={metaData.recaptchaSiteKey} />
          </Grid>
          <Grid item md={12} xs={12}>
            <ErrorMessage error={error} />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              color="primary"
              className={classes.submit}
              disabled={submitDisabled || isSubmitting}
            >
              {isSubmitting && <CircularProgress size={16} className={classes.spinner} />}
              Register
            </Button>
          </Grid>
        </Grid>
      </Form>
    </>
  )
}

const searchParams = new URLSearchParams(window.location.search);
const initialValues = {
  token: searchParams.get('token'),
  email: searchParams.get('email'),
  password: '',
  confirmPassword: '',
  acceptTerms: false,
}

const reduxFormConfig = {
  form: 'signUpFormConfirm',
  initialValues,
  validate: validationService.syncValidation(signUpConfirmValidationSchema()),
  asyncValidate: validationService.asyncValidate,
  asyncBlurFields: [],
  onSubmit: registerConfirm,
}

const ReduxSignUpConfirm = reduxForm(reduxFormConfig)(SignUpConfirm)

const mapStateToProps = state => {
  const {
    form: { signUpConfirm },
  } = state

  return {
    formValues: getFormValues('signUpFormConfirm')(state),
    signUpConfirm,
  }
}

const connectedSignUpConfirm = connect(mapStateToProps)(ReduxSignUpConfirm)

export default withInit(connectedSignUpConfirm)
