import React, { useEffect, useState } from 'react'
import { Field, Form, reduxForm, getFormValues } from 'redux-form'
import resetPasswordFields from '../../config/resetPassword-fields'
import { resetPasswordAction } from '../../utils/helpers'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Paper, Typography, Button, FormHelperText, Box, CircularProgress } from '@material-ui/core'
import { resetPasswordValidationSchema } from '../../utils/validationSchema'
import validationService from '../../services/validation-service'
import styles from './ResetPasswordStyles'
import queryString from 'query-string'
import withInit from '../../components/containers/withInit'

const useStyles = makeStyles(styles)

const ResetPassword = props => {
  const {
    error,
    handleSubmit,
    asyncValidating,
    pristine,
    valid,
    submitting,
    localisation,
    location,
    isSubmitting,
    setIsSubmitting,
    metaData: { resetAccount },
    match: {
      params: { partnerId },
    },
  } = props

  const classes = useStyles()
  const [showPassword, setShowPassword] = useState(false)
  const submitDisabled = !!(pristine || !valid || asyncValidating || submitting)

  const qs = queryString.parse(location.search)
  const passwordReset = qs.rt || undefined
  const fields = resetPasswordFields(localisation.pageText)

  const hasResetGreeting = resetAccount && resetAccount.firstName
  const resetGreeting = (
    <Typography component="p" className={classes.title}>
      Hello {resetAccount.firstName}, you have requested a password reset for
      <strong> {resetAccount.email}</strong>.<br />
      Enter your new password
    </Typography>
  )

  const sucessfulPasswordReset = passwordReset && (
    <Grid item container md={12} sm={12} xs={12}>
      <Paper elevation={0} className={classes.passwordReset}>
        <FormHelperText className={classes.passwordResetMessage}>Your password has been reset!</FormHelperText>
      </Paper>
    </Grid>
  )

  const resetTokenExpired = resetAccount && !resetAccount.firstName
  const resetTokenExpiredMessage = (
    <Box className={classes.header}>
      <Typography component="h3" variant="h5" color="primary" className={classes.title}>
        Reset Password
      </Typography>
      <Typography component="p" className={classes.paragraph}>
        Your reset link may have expired. Click on the button below to try again
      </Typography>
      <Button
        variant="contained"
        href={`/forgottenPassword/${partnerId || ''}`}
        color="primary"
        className={classes.button}
      >
        Request new token
      </Button>
    </Box>
  )
  const resetPasswordForm = (
    <>
      {' '}
      <Box className={classes.header}>
        <Typography component="h3" variant="h5" color="primary" className={classes.title}>
          Forgot Your Password?
        </Typography>
      </Box>
      <Form className={classes.form} onSubmit={handleSubmit}>
        {hasResetGreeting && resetGreeting}
        <Grid container>{sucessfulPasswordReset}</Grid>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12} className={classes.passwords}>
            <Field
              {...fields.password}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12} className={classes.passwords}>
            <Field
              {...fields.confirmPassword}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              disabled={isSubmitting}
            />
          </Grid>
          {error && (
            <Grid item container md={12} sm={12} xs={12}>
              <Paper elevation={0} className={classes.errorMessage}>
                <FormHelperText className={classes.errorHelper}>{error}</FormHelperText>
              </Paper>
            </Grid>
          )}

          <Grid item container md={12} sm={12} xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              color="primary"
              className={classes.submit}
              disabled={submitDisabled || isSubmitting}
            >
              {isSubmitting && <CircularProgress size={16} className={classes.spinner} />}
              Reset Password
            </Button>
          </Grid>
        </Grid>
      </Form>
    </>
  )

  useEffect(() => {
    document.title = 'Reset Password'
  }, [])

  useEffect(() => {
    if (submitting) {
      setIsSubmitting(true)
    }
  }, [submitting, setIsSubmitting])

  return resetTokenExpired ? resetTokenExpiredMessage : resetPasswordForm
}
const initialValues = {
  password: '',
  confirmPassword: '',
}

const ReduxResetPassword = reduxForm({
  form: 'resetPasswordForm',
  initialValues,
  validate: validationService.syncValidation(resetPasswordValidationSchema),
  onSubmit: resetPasswordAction,
})(ResetPassword)

const mapStateToProps = state => {
  const {
    form: { resetPasswordForm },
  } = state

  return {
    formValues: getFormValues('resetPasswordForm')(state),
    resetPasswordForm,
  }
}

const connectedSignInForm = connect(mapStateToProps)(ReduxResetPassword)

export default withInit(connectedSignInForm)
