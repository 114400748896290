import img00 from '../assets/images/0000.jpg'
import img01 from '../assets/images/0001.jpg'
import img02 from '../assets/images/0002.jpg'
import img03 from '../assets/images/0003.jpg'
import img04 from '../assets/images/0004.jpg'
import img05 from '../assets/images/0005.jpg'
import img06 from '../assets/images/0006.jpg'
import img07 from '../assets/images/0007.jpg'
import img08 from '../assets/images/0008.jpg'
import img09 from '../assets/images/0009.jpg'
import { pickImage } from '../utils/helpers'

const defaultImages = [img00, img01, img02, img03, img04, img05, img06, img07, img08, img09]

export default pickImage(defaultImages)
