const styles = theme => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    maxWidth: '800px',
  },

  root: {
    height: '100vh',
  },
  title: { margin: theme.spacing(2, 0, -1) },
  image: {
    backgroundImage: `url(${theme.coverImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(16, 8, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 4, 0),
    },
  },
  headline: { marginTop: theme.spacing(10), textAlign: 'center' },
  paragraph: { marginTop: theme.spacing(4), textAlign: 'center' },
  button: {
    margin: theme.spacing(0, 2),
    color: '#fff',
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
  },
})

export default styles
