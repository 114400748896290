import React from 'react'
import { FormControlLabel, Checkbox } from '@material-ui/core'

const CheckBoxField = ({ input, children }) => {
  return (
    <FormControlLabel
      control={<Checkbox checked={input.value ? true : false} onChange={input.onChange} />}
      label={children}
    />
  )
}
export default CheckBoxField
