import React from 'react'
import { FormControl, FormHelperText, InputLabel, Select } from '@material-ui/core'

const SelectField = ({ input, label, meta: { touched, invalid, error }, children, ...custom }) => {
  return (
    <FormControl fullWidth error={!!(touched && error)} required>
      <InputLabel>{label}</InputLabel>
      <Select {...input} {...custom} children={children} />
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export default SelectField
