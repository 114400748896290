const styles = theme => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    margin: 0,
  },

  root: {
    height: '100vh',
    position: 'fixed',
    opacity: '1',
    zIndex: '999',
  },
  title: { margin: theme.spacing(2, 0, -1) },
  image: {
    backgroundColor: '#e0e0e0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    padding: theme.spacing(8, 8, 0),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 4, 0),
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: '#fff',
  },
  passwords: {
    marginTop: theme.spacing(2),
  },

  personalDetails: {
    margin: theme.spacing(4, 0, -2),
  },
  companyDetails: {
    margin: theme.spacing(4, 0, -2),
  },
  spinner: {
    marginRight: '16px',
  },
  check: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  checkbox: {
    marginRight: '20px',
  },
})

export default styles
