const styles = theme => ({
  IEMessage: {
    display: 'table',
    margin: '100px auto 0 auto',
    maxWidth: '800px',
    height: '100%',
    fontFamily: 'Quicksand',
    textAlign: 'center'
  },
  IEContainer: {
    display: 'table-cell',
    verticalAlign: 'middle',
    border: '1px solid #dfdfdf',
    padding: '40px',
    boxShadow: '5px 5px 10px #eee'
  },
  logo: {
    marginBottom: '15px' 
  },
  instruction: {
    marginBottom: 0
  }
})

export default styles
