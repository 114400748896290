import { useState, useEffect } from 'react'

const defaultOpts = {
  mode: 'cors', // no-cors, cors, *same-origin
  headers: {
    'Content-Type': 'application/json',
  },
}
export const useFetch = (input, opts = defaultOpts, depArray = []) => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const { readBody = body => body.json(), ...init } = opts
  useEffect(() => {
    ;(async () => {
      setLoading(true)
      try {
        const response = await fetch(input, init)
        if (response.ok) {
          const body = await readBody(response)
          setData(body)
        } else {
          setError(new Error('Reload your page' || response.statusText))
        }
      } catch (e) {
        setError(e)
      }
      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...depArray])
  return [error, loading, data]
}
