const styles = theme => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    margin: 0,
  },
  root: {
    height: '100vh',
    position: 'relative',
  },
  title: { margin: theme.spacing(4, 0, 2), [theme.breakpoints.down('sm')]: { margin: theme.spacing(6, 0, 4) } },
  image: {
    backgroundImage: `url(${theme.coverImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(6, 4, 0),
      borderRadius: 0,
      borderTop: `8px solid ${theme.palette.primary.main}`,
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  msButton: {
    margin: theme.spacing(1, 0, 0),
    color: '#fff',
    backgroundColor: '#336699',
    textTransform: 'none'
  },
  submit: {
    margin: theme.spacing(1, 0, 0),
    color: '#fff',
  },
  separator: {
    height: '1px',
    margin: '24px 0 12px',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  passwords: {
    marginTop: theme.spacing(2),
  },
  errorHelper: { marginTop: 0, color: '#fff' },
  errorMessage: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    padding: theme.spacing(2, 2),
    margin: theme.spacing(2, 0),
    backgroundColor: theme.palette.error.light,
  },
  passwordReset: {
    display: 'flex',
    width: '100%',
    backgroundColor: theme.palette.primary.light,
    justifyContent: 'center',
    padding: theme.spacing(2, 2),
    margin: theme.spacing(2, 0),
  },
  passwordResetMessage: {
    marginTop: 0,
    color: '#fff',
  },
})

export default styles
