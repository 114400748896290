const isDevEnv = process.env.NODE_ENV === 'development'

class ApiService {
  _apiBase = isDevEnv ? 'https://icompleatcentralportal-qa.azurewebsites.net/api' : '/api'
  getResource = async url => {
    const res = await fetch(`${this._apiBase}${url}`)
    return await res.json()
  }

  postResource = async (url, payload) => {
    const res = await fetch(`${this._apiBase}${url}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (!res.ok) {
      throw new Error(`API-service error: Could not fetch ${url}, recieved ${res.status}`)
    }
    return await res.json()
  }

  getRegistrationMeta = async () => {
    let [, p2] = window.location.pathname.split('/')
    p2 = p2.toLowerCase()
    // add existing routes here, to let url/partnerId lead to login page
    const wrongRoute = !(
      p2 === '' ||
      p2 === 'login' ||
      p2 === 'register' ||
      p2 === 'forgottenpassword' ||
      p2 === 'punchin' ||
      p2 === 'resetforgottenpassword'
    )
    if (wrongRoute) {
      return { localisation: undefined }
    }
    const reqUrl = p2 === '' ? '/login/' : window.location.pathname
    const res = await this.getResource(reqUrl)
    return res
  }

  getCountryList = async () => {
    const res = await this.getResource(`/register/countryList`)
    return res
  }

  getSoftwareList = async iso => {
    const res = await this.getResource(`/register/accountingSoftwareList?iso3code=${iso}`)
    return res
  }

  checkTransaction = async (tenantId, transactionId) => {
    const res = await this.getResource(`/punchin/CheckTransaction/${tenantId}/${transactionId}`)
    return res
  }

  validateCompanyName = async (companyName, registrationType) => {
    const res = await this.postResource(`/register/validateCompanyName`, { companyName, registrationType })
    return res
  }

  validateEmail = async email => {
    const res = await this.postResource(`/register/validateEmailAddress`, { emailAddress: email })
    return res
  }

  submitRegistration = async data => {
    const res = await this.postResource(`/register/`, { ...data })
    return res
  }

  submitRegistrationConfirm = async data => {
    const res = await this.postResource(`/register/confirm`, { ...data })
    return res
  }

  submitLogin = async data => {
    const res = await this.postResource(`/login/`, { ...data })
    return res
  }

  submitLoginCapture = async data => {
    const res = await this.postResource(`/login/capture-external`, { ...data })
    return res
  }

  resetPasswordAskForEmail = async email => {
    const res = await this.postResource(`/forgottenpassword/`, { email: email })
    return res
  }

  resetPassword = async data => {
    const res = await this.postResource(`/resetforgottenpassword/`, { ...data })
    return res
  }
}

export default new ApiService()
