import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from './FooterStyles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faTwitterSquare, faLinkedin, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons'
import { Link } from '@material-ui/core'
const useStyles = makeStyles(styles)

const Footer = ({ socialMedia = {} }) => {
  const classes = useStyles()
  const date = new Date()
  const currentYear = date.getFullYear()
  return (
    <footer className={classes.footer}>
      <div className={classes.iconContainer}>
        <Link href={socialMedia.facebook}>
          <FontAwesomeIcon icon={faFacebookSquare} size="2x" className={classes.icon} />
        </Link>
        <Link href={socialMedia.twitter}>
          <FontAwesomeIcon icon={faTwitterSquare} size="2x" className={classes.icon} />
        </Link>
        <Link href={socialMedia.linkedIn}>
          <FontAwesomeIcon icon={faLinkedin} size="2x" className={classes.icon} />
        </Link>
        <Link href={socialMedia.youTube}>
          <FontAwesomeIcon icon={faYoutubeSquare} size="2x" className={classes.icon} />
        </Link>
      </div>
      <p className={classes.copy}>
        {`Copyright ${currentYear} © `}
        <Link href="https://www.compleatsoftware.com" className={classes.copyLink}>
          Compleat Software
        </Link>
      </p>
    </footer>
  )
}

Footer.defaultProps = {
  socialMedia: {
    facebook: 'https://www.facebook.com/compleatsoftware/',
    linkedIn: 'https://www.linkedin.com/company/compleat-software-ltd',
    twitter: 'https://twitter.com/CompleatP2P',
    youTube: 'https://www.youtube.com/watch?v=4QQIDCI1LNE',
  },
}

export default Footer
