import React, { useEffect } from 'react'

import { Box, Button, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { ReactComponent as Logo } from '../../assets/icompleat.svg'
import styles from './ErrorStyles'

const useStyles = makeStyles(styles)

const ErrorPage = ({ link }) => {
  const classes = useStyles()
  useEffect(() => {
    document.title = 'Uh-oh! Something went wrong.'
  }, [])

  return (
    <Paper className={classes.paper} elevation={0}>
      <Box className={classes.header}>
        <Logo />
        <Typography component="h2" variant="h4" className={classes.headline}>
          Uh-oh! Something went wrong.
        </Typography>
        <Typography component="p" className={classes.paragraph}>
          We've recorded the problem and will investigate it. In the meantime, why not try again?
        </Typography>
        <Box className={classes.buttonContainer}>
          <Button variant="contained" href={link} color="primary" className={classes.button}>
            Try Again
          </Button>
        </Box>
      </Box>
    </Paper>
  )
}

export default ErrorPage
