import PasswordField from '../components/fields/PasswordField'

const fields = (locale = {}) => ({
  password: {
    name: 'password',
    component: PasswordField,
    label: 'Enter Your New Password',
    inputProps: {
      autoComplete: 'off',
    },
    fullWidth: true,
    hasPopover: true,
    required: true,
    showEye: true,
  },
  confirmPassword: {
    name: 'confirmPassword',
    component: PasswordField,
    label: 'Confirm Your New Password',
    inputProps: {
      autoComplete: 'off',
    },
    fullWidth: true,
    required: true,
    showEye: false,
  },
})

export default fields
