import React from 'react'

import { Button } from '@material-ui/core'

import MSLogo from '../../assets/ms-logo.svg';

const AzureAuthenticationButton = (props) => {
  const aadlogin = async () => {
    window.location = '/api/thirdpartyauth/AzureAdLogin' + GetReturnUrlparam();
  }

  function GetReturnUrlparam(){
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let value = params.returnUrl;
    if(value)
    {
      return "?returnUrl=" + value;
    }
    else{
      return "";
    }
  }
  return (
    <Button
      fullWidth
      variant="contained"
      size="large"
      color="primary"
      className={props.className}
      onClick={aadlogin}
      startIcon={<img src={MSLogo} alt='MS Logo' width='14' height='14' />
      }
    >
      {props.buttonText}

    </Button>
  )
}

export default AzureAuthenticationButton;