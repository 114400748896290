import React, { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import ContentLoader from 'react-content-loader'

const RecaptchaLoader = () => (
  <div style={{ width: '304px', height: '76px' }}>
    <ContentLoader height={80} width={304} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
      <rect x="0" y="0" rx="5" ry="5" width="304" height="80" />
    </ContentLoader>
  </div>
)

const RecaptchaField = ({ siteKey, input }) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 500)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  return <>{siteKey && !loading ? <ReCAPTCHA sitekey={siteKey} size="normal" {...input} /> : <RecaptchaLoader />}</>
}

export default RecaptchaField
