import InputField from '../components/fields/InputField/InputField'
import CheckBoxField from '../components/fields/CheckBoxField'
import PasswordField from '../components/fields/PasswordField'

const fields = (locale = { emailLabel: '', passwordLabel: '', rememberMe: '' }) => ({
  email: {
    name: 'email',
    component: InputField,
    label: locale.emailLabel || 'Email',
    autoComplete: 'off',
    fullWidth: true,
    required: true,
  },

  password: {
    name: 'password',
    showEye: true,
    component: PasswordField,
    label: locale.passwordLabel || 'Password',
    inputProps: {
      autoComplete: 'off',
    },
    fullWidth: true,
    hasPopover: false,
    required: true,
  },
  rememberMe: {
    name: 'rememberMe',
    component: CheckBoxField,
    label: locale.rememberMe || 'Remember Me',
    required: false,
  },
})

export default fields
