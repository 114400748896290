import React from 'react'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },
  spinner: {
    position: 'absolute',
    top: 20,
    right: 0,
  },
}))

const InputField = props => {
  const {
    label,
    input,
    meta,
    meta: { asyncValidating, touched, invalid, error },
    ...custom
  } = props
  const styles = useStyles()

  return (
    <Box className={styles.container}>
      {asyncValidating && <CircularProgress size={20} className={styles.spinner} />}
      <TextField
        label={label}
        placeholder={label}
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        {...custom}
      />
    </Box>
  )
}

export default InputField
