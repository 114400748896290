import InputField from '../components/fields/InputField/InputField'

const fields = (locale = {}) => ({
  email: {
    name: 'email',
    component: InputField,
    label: locale.emailLabel || 'Email',
    autoComplete: 'off',
    fullWidth: true,
    required: true,
  },
})

export default fields
