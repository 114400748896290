const styles = theme => ({
  errorHelper: { marginTop: 0, color: '#fff' },
  errorMessage: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    padding: theme.spacing(2, 2),
    margin: theme.spacing(1, 0, 0, 0),
    backgroundColor: theme.palette.error.light,
  },
})

export default styles
