import React from 'react'
import { Grid, Paper, Box, Hidden } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import Spinner from './Spinner'
import styles from './LoadingStyles'
import { makeStyles } from '@material-ui/core/styles'
import { Transition } from 'react-transition-group'
import './loading.scss'

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0, display: 'none' },
}

const defaultStyle = {
  transition: `opacity 1000ms ease-out`,
  opacity: 1,
}

const useStyles = makeStyles(styles)

const RegisterPageSkeleton = () => {
  const classes = useStyles()
  return (
    <Box className={classes.header}>
      <Skeleton width={176} height={64} />
      {/* Personal Details */}
      <Grid container spacing={1}>
        <Grid item md={12} sm={6} xs={12}>
          <Skeleton width={210} height={48} />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Skeleton width={140} height={48} />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Skeleton width={'100%'} height={48} />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Skeleton width={'100%'} height={48} />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Skeleton width={'100%'} height={48} />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Skeleton width={'100%'} height={48} />
        </Grid>
      </Grid>
      <br />
      <br />
      {/* Company Details */}
      <Grid container spacing={2}>
        <Grid item md={12} sm={6} xs={12}>
          <Skeleton width={200} height={48} />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Skeleton width={'100%'} height={48} />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Skeleton width={'100%'} height={48} />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Skeleton width={'100%'} height={48} />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Skeleton width={'100%'} height={48} />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Skeleton width={'100%'} height={48} />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Skeleton width={'100%'} height={48} />
        </Grid>
        <Grid item md={12} sm={12} xs={12} className={classes.check}>
          <Skeleton width={24} height={24} className={classes.checkbox} variant="rect" />
          <Skeleton width={'100%'} height={24} className={classes.checkboxText} variant="rect" />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Skeleton width={320} height={90} variant="rect" />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Skeleton width={'100%'} height={40} className={classes.submit} variant="rect" />
        </Grid>
      </Grid>
    </Box>
  )
}

const LoginPageSkeleton = () => {
  const classes = useStyles()
  return (
    <Box className={classes.header}>
      <Skeleton width={210} height={64} />
      <Grid container spacing={1}>
        <Grid item md={12} sm={6} xs={12}>
          <Skeleton width={110} height={48} />
        </Grid>
        {/* <Grid item md={12} sm={12} xs={12}>
          <Skeleton width={140} height={48} />
        </Grid> */}
        <Grid item md={12} sm={12} xs={12}>
          <Skeleton width={'100%'} height={48} />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Skeleton width={'100%'} height={48} />
        </Grid>
      </Grid>

      {/* Company Details */}
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <Skeleton width={'100%'} height={40} className={classes.submit} variant="rect" />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Skeleton width={'100%'} height={48} />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Skeleton width={'100%'} height={48} />
        </Grid>
      </Grid>
    </Box>
  )
}

const Loading = ({ leftColumnWidth, rightColumnWidth, loading }) => {
  const classes = useStyles()
  return (
    <Transition in={loading} exit={!loading} timeout={1000}>
      {state => (
        <div style={{ ...defaultStyle, ...transitionStyles[state] }}>
          <Grid container component="main" className={classes.root}>
            <Hidden smDown>
              <Grid item xs={false} sm={false} md={leftColumnWidth} className={classes.image}>
                <Spinner />
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={12} md={rightColumnWidth} component={Paper} elevation={2} square>
              <Paper className={classes.paper} elevation={0}>
                {leftColumnWidth === 6 ? <RegisterPageSkeleton /> : <LoginPageSkeleton />}
              </Paper>
            </Grid>
          </Grid>
        </div>
      )}
    </Transition>
  )
}

export default Loading
