import AuthenticationFailed from './Pages/AuthenticationFailed'
import ForgotPassword from './Pages/ForgotPassword'
import NotFound from './Pages/NotFound'
import PunchIn from './Pages/PunchIn'
import ResetPassword from './Pages/ResetPassword'
import SignInCapture from './Pages/SignInCapture'
import SignInForm from './Pages/SignInForm'
import SignUpConfirm from './Pages/SignUpConfirm'
import SignUpForm from './Pages/SignUpForm'

const routes = [
  //Register
  { path: '/register', name: 'SignUp', component: SignUpForm, exact: true },
  { path: '/register/confirm', name: 'SignUpConfirm', component: SignUpConfirm, exact: true },
  { path: '/register/:partnerId', name: 'SignUp', component: SignUpForm, exact: true },
  // Forgotten Password
  { path: '/forgottenPassword/', name: 'ForgotPassword', component: ForgotPassword, exact: true },
  {
    path: '/forgottenPassword/:partnerId/',
    name: 'ForgotPassword',
    component: ForgotPassword,
    exact: true,
  },
  // Reset Password
  { path: '/resetforgottenpassword/:tenantId/:resetId', name: 'ResetPassword', component: ResetPassword, exact: true },
  {
    path: '/resetforgottenpassword/:partnerId/:tenantId/:resetId',
    name: 'ResetPassword',
    component: ResetPassword,
    exact: true,
  },
  //Not Found

  //Login
  { path: '/login', name: 'SignIn', component: SignInForm, exact: true },
  { path: '/login/capture-external', name: 'SignInCapture', component: SignInCapture, exact: true },
  { path: '/login/:partnerId', name: 'SignIn', component: SignInForm, exact: true },
  { path: '/', name: 'SignIn', component: SignInForm, exact: true },
  // { path: '/:partnerId', name: 'SignIn', component: SignInForm, exact: false },
  { path: '/not-found', name: 'NotFound', component: NotFound },
  { path: '/punchin/:tenantId/:transactionId', name: 'PunchIn', component: PunchIn },
  { path: '/auth-failed', name: 'AuthFailed', component: AuthenticationFailed },
  { name: 'NotFound', component: NotFound },
]

export default routes
