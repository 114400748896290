import queryString from 'query-string'
import React from 'react'
import { Helmet } from 'react-helmet'
import { I18nextProvider } from 'react-i18next'

import CssBaseline from '@material-ui/core/CssBaseline'
import blueGrey from '@material-ui/core/colors/blueGrey'
import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'

import ErrorPage from '../../Pages/Error'
import i18n from '../../config/i18n'
import icompleatTheme from '../../theme'
import randomDefaultImage from '../../theme/defaultImages'
import { getDeepObject, pickImage } from '../../utils/helpers'
import { useFetch } from '../../utils/hooks'
import Loading from '../Loading'
import TwoColumnLayout from './Layout'

const API_URL =
  window.ENVIRONMENT && window.ENVIRONMENT.apiRootUrl
    ? `${window.ENVIRONMENT.apiRootUrl}/api`
    : 'https://icompleatcentralportal-qa.azurewebsites.net/api'

const extractThemeOptions = ({ partnerConfiguration }) => {
  const { accentColour, splashImages } = partnerConfiguration
  const image = pickImage(splashImages)
  return { accentColour, image }
}

const defaultThemeOptions = {
  palette: { primary: { main: '#00b09d' } },
  image: randomDefaultImage,
}

const RIGHT_COLUMN_DEFAULT = 3
const RIGHT_COLUMN_REGISTER = 6

const withInit = Wrapped => props => {
  const {
    location,
    location: { pathname },
    match: {
      params: { partnerId },
    },
  } = props
  
  const metaPathname = pathname === '/' ? '/login' : pathname
  const [error, loading, metaData] = useFetch(`${API_URL}${metaPathname}`)
  const { localisation, socialMedia, whiteLabelTenantConfig } = metaData
  const { returnUrl } = queryString.parse(location.search)
  const isRegistrationPage = window.location.pathname.toLowerCase().includes('register')
  const rightColumnWidth = isRegistrationPage ? RIGHT_COLUMN_REGISTER : RIGHT_COLUMN_DEFAULT
  const leftColumnWidth = 12 - rightColumnWidth

  let themeOptions = {}

  i18n.init({
    resources: {
      en: localisation,
    },
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  })

  if (whiteLabelTenantConfig) {
    themeOptions = extractThemeOptions(whiteLabelTenantConfig)
  } else {
    themeOptions = defaultThemeOptions
  }
  // const pageTitle = (metaData.localisation && metaData.localisation.pageTitle) || 'iCompleat'

  const capitalize = str => (str ? str.charAt(0).toUpperCase() + str.slice(1) : null)

  const partnerIcon = getDeepObject(['whiteLabelTenantConfig', 'partnerConfiguration', 'favIconUrl'], metaData)
  const customLogo = getDeepObject(['whiteLabelTenantConfig', 'partnerConfiguration', 'logoUrl'], metaData)
  const isPartner = partnerId
  const tenantName = (isPartner && capitalize(getDeepObject(['whiteLabelTenantName'], metaData))) || 'iCompleat'
  const hasInviteCode = metaData.siteSetupMode === 4
  const isAmazonBusiness = isPartner && isPartner.toUpperCase() === "AUTOMATION";
  const isCompleat = (pathname === '/login/capture-external' || pathname === '/register/confirm');
  
  const theme = createTheme({
    palette: {
      primary: { main: themeOptions.accentColour || '#00b09d' },
      secondary: blueGrey,
    },
    ...icompleatTheme,
    coverImage: themeOptions.image,
    isAmazonBusiness: isAmazonBusiness
  })

  if (error) return <ErrorPage link={location.pathname} />
  // if (loading) return <Loading leftColumnWidth={6} rightColumnWidth={6} />
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link id="favicon" rel="icon" href={partnerIcon} type="image/x-icon" />
      </Helmet>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          {loading ? (
            <Loading leftColumnWidth={leftColumnWidth} rightColumnWidth={rightColumnWidth} loading={loading} />
          ) : (
            <TwoColumnLayout
              socialMedia={socialMedia}
              customLogo={customLogo}
              tenantName={tenantName}
              isPartner={isPartner}
              isCompleat={isCompleat}
              loading={loading}
              leftColumnWidth={leftColumnWidth}
              rightColumnWidth={rightColumnWidth}
            >
              <Wrapped
                {...props}
                metaData={metaData}
                localisation={localisation}
                hasInviteCode={hasInviteCode}
                returnUrl={returnUrl}
                loading={loading}
                isAmazonBusiness={isAmazonBusiness}
              />
            </TwoColumnLayout>
          )}
        </I18nextProvider>
      </ThemeProvider>
    </>
  )
}

export default withInit
