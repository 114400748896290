import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { ReactComponent as ArrowLogo } from '../../assets/arrowLogo.svg'
import styles from './IEMessageStyles'

const useStyles = makeStyles(styles)

const IEMessage = () => {
  const classes = useStyles()

  return (
    <div className={classes.IEMessage}>
      <div className={classes.IEContainer}>
        <ArrowLogo className={classes.logo}/>       

        <h3>The Internet Explorer browser is no longer supported.</h3>
        <p className={classes.instruction}>
          Please upgrade to one of the modern browsers such as Edge, Chrome or Firefox.
        </p>
      </div>
    </div>
  )
}

export default IEMessage
