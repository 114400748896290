const styles = theme => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    margin: 0,
  },

  root: {
    position: 'relative',
    height: '100vh',
    overflowX: 'hidden',
  },
  title: { margin: theme.spacing(2, 0, -1) },
  image: {
    backgroundImage: `url(${theme.coverImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: `${theme.isAmazonBusiness ? "contain" :"cover"}`,
    backgroundPosition: `${theme.isAmazonBusiness ? "center 100px" :"top center"}`,
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(8, 8, 0),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 4, 0),
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: '#fff',
  },
  passwords: {
    marginTop: theme.spacing(2),
  },

  personalDetails: {
    margin: theme.spacing(4, 0, -2),
  },
  companyDetails: {
    margin: theme.spacing(4, 0, -2),
  },
  spinner: {
    marginRight: '16px',
  },
  footNote: {
    fontSize: '12px',
  },
  linearProgress: {
    position: 'absolute',
    width: '100%',
    zIndex: 999,
  },
  recaptcha: {
    minHeight: '80px',
  },
})

export default styles
