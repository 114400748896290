import React from 'react'
import { Paper, Typography } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    zIndex: '1500',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    top: '3rem',
    padding: theme.spacing(2, 2),
  },
}))

const renderErrorList = errors =>
  Array.isArray(errors) ? errors.map((err, i) => <ListItem key={i}>{err}</ListItem>) : null

const ErrorList = ({ error }) => {
  const styles = useStyles()
  const hasError = error && error.length > 0
  if (!hasError) return null
  return (
    <Paper className={styles.paper} elevation={4} square>
      <Typography variant="h6" component="h6">
        Password must:
      </Typography>
      <List>{renderErrorList(error)}</List>
    </Paper>
  )
}

export default ErrorList
