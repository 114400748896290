import React, { useState } from 'react'

import { FormControl, FormHelperText, InputLabel, Input, InputAdornment, IconButton } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Popover from '../Popover/'

const PasswordField = props => {
  const {
    hasPopover,
    input,
    inputProps,
    disabled,
    showPassword,
    setShowPassword,
    showEye,
    meta: { touched, error, invalid },
  } = props
  // const [showPassword, setShowPassword] = useState(false)
  const [showPopover, setShowPopover] = useState(false)

  const handleClickShowPassword = e => {
    setShowPassword(!showPassword)
  }
  const passwordMethods = hasPopover
    ? {
        onFocus: () => setShowPopover(true),
        onBlur: event => {
          input.onBlur(event)
          setShowPopover(false)
        },
      }
    : {}

  return (
    <FormControl fullWidth required>
      <InputLabel htmlFor={input.name} error={touched && invalid}>
        {props.label}
      </InputLabel>
      <Input
        {...input}
        id={input.name}
        type={showPassword ? 'text' : 'password'}
        error={error && touched && invalid}
        endAdornment={
          showEye && (
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }
        inputProps={inputProps}
        {...passwordMethods}
        disabled={disabled}
      />
      {hasPopover && showPopover && <Popover error={error} />}
      {!showPopover && touched && error && (
        <FormHelperText error id="my-helper-text">
          {typeof error === 'object' ? error[0] : error}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default PasswordField
