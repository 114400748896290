import Quicksand from '../assets/fonts/Quicksand.woff2'

const quicksandRegular = {
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Quicksand'),
    url(${Quicksand}) format('woff2')
  `,
}

const quicksandBold = {
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('Quicksand'),
    url(${Quicksand}) format('woff2')
  `,
}

const typography = {
  fontFamily: [
    'Quicksand',
    'Arial',
    'sans-serif',
  ].join(','),
  h1: {
    fontWeight: 700
  },
  h2: {
    fontWeight: 700
  },
  h3: {
    fontWeight: 700
  },
  h4: {
    fontWeight: 700
  },
  h5: {
    fontWeight: 700
  },
  h6: {
    fontWeight: 700
  },
}

const overrides = {
  MuiCssBaseline: {
    '@global': {
      '@font-face': [quicksandRegular, quicksandBold],
    },
  },
  MuiFormLabel: {
    root: {
      fontSize: 14,
    }
  },
}

const icompleatTheme = {
  typography,
  overrides,
}

export default icompleatTheme
