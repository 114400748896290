import { useState } from 'react'
import { SubmissionError } from 'redux-form'

import API from '../services/api-service'

const redirectLogin = ({ redirectUrl }) => (window.location = redirectUrl)
const redirectReset = () => {
  const params = window.location.pathname.split('/')
  let partnerName = ''
  if (params.length === 5) {
    partnerName = `/${params[2]}` //in case if it's partnered password reset
  }
  const redirectUrl = `/login${partnerName}?rt=PasswordReset`
  window.location = redirectUrl
}
const redirectRegister = ({ loginUrl }) => (window.location = loginUrl)

const rememberUser = ({ email }) => localStorage.setItem('username', email)

const transformSignUpValues = (values, metaData) => {
  const {
    firstName,
    lastName,
    phoneNumber,
    email,
    companyName,
    country,
    accountingSoftware,
    password,
    inviteCode,
  } = values
  const { registrationType, siteSetupMode, whiteLabelTenantName } = metaData

  return {
    FirstName: firstName,
    LastName: lastName,
    Email: email,
    CompanyName: companyName,
    TelephoneNumber: phoneNumber,
    CountryIso3Code: country,
    AccountingSoftware: accountingSoftware,
    Password: password,
    InviteCode: inviteCode,
    RegistrationType: registrationType,
    WhiteLabelTenantName: whiteLabelTenantName,
    SiteSetupMode: siteSetupMode,
  }
}


const transofrmSignInValues = (values, ops) => {
  const { email, password } = values
  const { returnUrl } = ops
  return { Email: email, Password: password, ReturnUrl: returnUrl }
}

const transformResetPasswordValues = (password, { tenantId, resetId }) => {
  return { tenantId, resetId, password }
}

export const register = async (values, dispatch, props) => {
  const reqBody = transformSignUpValues(values, props.metaData)
  return await API.submitRegistration(reqBody)
    .then(res => {
      if (res.status === 5) {
        redirectRegister(res)
      } else {
        throw new Error(res.status)
      }
    })
    .catch(err => {
      throw new SubmissionError({ _error: registerErrorsMapper(err.message) })
    })
}


export const registerConfirm = async (values, dispatch, props) => {
  delete values.recaptcha;
  return await API.submitRegistrationConfirm(values)
    .then(res => {
      if (res.status === 5) {
        redirectRegister(res)
      } else {
        throw new Error(res.status)
      }
    })
    .catch(err => {
      throw new SubmissionError({ _error: registerErrorsMapper(err.message) })
    })
}

export const login = async (values, dispatch, opts) => {
  // const { returnUrl } = opts
  if (values.rememberMe === true) {
    rememberUser(values)
  }
  const reqBody = transofrmSignInValues(values, opts)
  return await API.submitLogin({ ...reqBody })
    .then(res => {
      if (res.result === 1 || res.result === 5) {
        // active or reset password
        // if reset password send to reset it
        redirectLogin(res)
      } else {
        throw new Error(res.result)
      }
    })
    .catch(err => {
      throw new SubmissionError({ _error: submitErrorsMapper(err.message) })
    })
}

export const loginCapture = async (values, dispatch, opts) => {
  // const { returnUrl } = opts
  if (values.rememberMe === true) {
    rememberUser(values)
  }
  const reqBody = transofrmSignInValues(values, opts)
  return await API.submitLoginCapture({ ...reqBody })
    .then(res => {
      if (res.result === 1 || res.result === 5) {
        // active or reset password
        // if reset password send to reset it
        redirectLogin(res)
      } else {
        throw new Error(res.result)
      }
    })
    .catch(err => {
      throw new SubmissionError({ _error: submitErrorsMapper(err.message) })
    })
}

export const requestResetPassword = async values => {
  return await API.resetPasswordAskForEmail(values.email)
}
export const resetPasswordAction = async (values, dispatch, props) => {
  const reqBody = transformResetPasswordValues(values.password, props.match.params)
  return await API.resetPassword(reqBody)
    .then(res => {
      if (res.result === 0) {
        redirectReset(res)
      } else {
        throw new Error(res.result)
      }
    })
    .catch(err => {
      throw new SubmissionError({ _error: resetPasswordErrorsMapper(err.message) })
    })
}

const registerErrorsMapper = errCode => {
  const errors = {
    1: 'NoSiteSetup',
    2: 'ModelInvalid',
    3: 'IsRobot',
    4: 'IsValid',
    5: 'Registered', // success
    6: 'An error has occurred. Please try again later.',
    7: 'Your invite code is not recognised.'
    }
  return errors[errCode]
}

const submitErrorsMapper = errCode => {
  const errors = {
    // 1: 'success'
    2: 'Invalid Login',
    3: 'Account Suspended',
    4: 'Account Inactive',
    5: 'Unknown error',
  }
  return errors[errCode]
}
const resetPasswordErrorsMapper = errCode => {
  const errors = {
    // 0: 'success'
    1: 'Reset failed - Internal Problem',
    2: 'Reset failed - Reset link is invalid',
    3: 'Unable to reset password',
  }
  return errors[errCode]
}

export function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key)
      return item ? item : initialValue
    } catch (error) {
      return initialValue
    }
  })

  const setValue = value => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      window.localStorage.setItem(key, valueToStore)
    } catch (error) {
      console.error(error)
    }
  }

  return [storedValue, setValue]
}

// pick a different image from the pool, every half hour
// export const pickImage = images => {
//   const time = new Date();
//   const currentHour = time.getHours();
//   const currentMinutes = time.getMinutes();
//   // return 1 or 0 for first half and second half hour
//   const halfHour = Math.floor(currentMinutes/30) % 2;
//   // multiply hours by 2 and add half an hour so we change the image on the half hour
//   const index = (currentHour * 2 + halfHour) % images.length;
//   return images[index];
// }

// cycle through images every 2 minutes for QA testing
export const pickImage = images => {
  const time = new Date();
  const currentMinutes = time.getMinutes();
  const index = (Math.floor(currentMinutes/2)) % images.length;
  return images[index];
}

export const getDeepObject = (p, o) => p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o)
