import React from 'react'
import { Route, Switch } from 'react-router-dom'
import routes from '../../routes'

const Routes = props => (
  <Switch>
    {routes.map(({ exact, path, component, title }, i) => (
      <Route exact={exact} path={path} component={component} title={title} key={i} />
    ))}
  </Switch>
)

export default Routes
