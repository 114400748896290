import React, { useEffect, useState } from 'react'

import { Box, CircularProgress, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import API from '../../services/api-service'
import styles from './PunchInStyles'

const useStyles = makeStyles(styles)

let interval;
const PunchIn = props => {
  let [message, setMessage] = useState("We're creating your transaction - please wait, and we'll redirect you")

  const classes = useStyles()
  const {
    match: {
      params: { transactionId = '', tenantId ='' },
    },
  } = props
  useEffect(() => {
    
  const LoadData =  async () =>  {
    try {
      const res = await API.checkTransaction(tenantId, transactionId);
      if (res.length > 0) {
        clearInterval(interval);
        if (res.includes('Error')) {
          const peices = res.split("|");
          let supplier = "your supplier";
          if (peices.length > 1) {
            supplier = peices[1];
          }
          setMessage("Sorry, there's been a problem creating your order, please go back to " + supplier + " and try again");
        }
        else {
        window.location = res;
        }
      }
      console.log(res)
    } catch (e) {
      console.log(e);
    }
  }
      LoadData();
      interval = setInterval(LoadData, 2000);
  }, 
  [transactionId, tenantId])

  return (
    <Paper className={classes.paper} elevation={0}>
      <Box className={classes.header}>
        <Typography component="h2" variant="h4" className={classes.headline}>
        {message}
        </Typography>    
        <CircularProgress size={24} className={classes.spinner} />    
      </Box>
    </Paper>
  )
}

export default PunchIn
